export const directiveData = [
  {
    icon: "fas fa-user-tie",
    title: "Presidente",
    names: ["Santiago Satrústegui Pérez de Villaamil"],
    className: "",
  },
  {
    icon: "fas fa-user-tie",
    title: "Vicepresidente",
    names: ["José Miguel Maté Salgado"],
    className: "",
  },
  {
    icon: "fas fa-user-tie",
    title: "Secretario",
    names: ["Joan Pons Gomar"],
    className: "",
  },
  {
    icon: "fas fa-user-tie",
    title: "Tesorero",
    names: ["Josep Soler Albertí"],
    className: "",
  },
  {
    icon: "fas fa-users",
    title: "Vocales",
    names: [
      "Adela Martín",
      "José Javier Nicolás Espín",
      "Carlos García Ciriza",
      "Emiliano Álvarez",
        "Francisco Javier Bello"
    ],
    fullCol: true,
    className: "",
  },
  {
    icon: "fas fa-users",
    title: "Asesor",
    names: [
      "Fernando Zunzunegui, Coordinador de Regulación de EFPA España",
    ],
    fullCol: true,
    className: "border-0",
  },
]

export const columns = [
  {
    title: "Comité de Acreditación y Certificación",
    content: `
      <p>Sus miembros han sido nombrados por la Junta de EFPA España pero actúan con autonomía e independencia. Gestionan todos los aspectos vinculados a la acreditación de programas formativos, los exámenes de certificación y las propuestas de concesión de los certificados que aprobará la Junta Directiva.</p>
      <p><strong>Presidente:</strong> José Manuel Ortiz</p>
      <p><strong>Miembros:</strong> Víctor Martínez<br>Martí Pachamé</p>
    `,
  },
  {
    title: "Comité de Comunicación",
    content: `
      <p>Trabajan conjuntamente con el gabinete de prensa y están a cargo del plan de divulgación, presentaciones y promoción de EFPA, en medios de comunicación e instituciones.</p>
      <p><strong>Presidente:</strong> Enrique Borrajeros</p>
    `,
  },
  {
    title: "Comité Deontológico",
    content: `
      <p>Su misión es la de supervisar e impulsar la implantación y cumplimiento del Código Ético.</p>
      <p><strong>Presidente:</strong> Jordi Melé-Carné</p>
      <p><strong>Vicepresidente:</strong> Carlos María Moreno</p>
      <p><strong>Secretario:</strong> Francisco J.Lara</p>
    `,
  },
  {
    title: "Comité de Servicios a Asociados",
    content: `
      <p>Trabajan en el desarrollo y mejora de la web corporativa, organización de seminarios, conferencias, congresos, ventajas de la tarjeta profesional y otros servicios a los miembros. Además, los delegados territoriales de EFPA España dependen y forman parte de este comité.</p>
      <p><strong>Presidente:</strong> Xavier Nicolás</p>
      <p><strong>Delegados territoriales:</strong><br>
          Andalucía Occidental: Ramón Padilla<br>
          Andalucía Oriental: José Antonio Pérez Muriel<br>
          Asturias: José María Montalvo<br>
          Aragón: Pilar Barcelona<br>
          Castilla y León: Guillermo Domínguez Torres<br>
          Castilla La Mancha (Toledo): José Maria Fuster de Barutell<br>
          C. Valenciana: Joaquín Maldonado Rubio<br>
          Extremadura: Luis Manuel Donaire<br>
          Galicia: Alejandra Pérez Peña<br>
          Islas Baleares: Enrique Juan De Sentmenat<br>
          Islas Canarias: Jesús Benito Álvarez Tremiño<br>
          La Rioja (Logroño): Juan Ignacio Córdoba Celma<br>
          Murcia: Juan Antonio Belmonte Cegarra<br>
          Navarra: Begoña Alfaro<br>
      </p>
    `,
  },
  {
    title: "Comité de auditoría interna",
    content: `
      <p><strong>Vocales:</strong><br>
      Victor Malet<br>
      Jordi Sullá
      </p>
    `,
  },
]

export const presidente = [`Santiago Satrústegui Pérez de Villaamil`]
export const vicepresidente = [`José Miguel Maté Salgado`]
export const secretario = [`Joan Pons Gomar`]
export const Tesorero = [`Josep Soler Albertí`]
export const Vocales = [`Adela Martín`, `José Javier Nicolás Espín`, `Carlos García Ciriza`, `Emiliano Álvarez`, `Francisco Javier Bello`]
export const Asesores = [`Fernando Zunzunegui, Coordinador de Regulación de EFPA España`]