import React from "react"
import PropTypes from "prop-types"

const Directive = props => {
  const { title, names, icon, className } = props

  const renderNames = () => {
    if (!names || !names.length) return

    return names.map((item, index) => (
      <li key={`directive-${index}`} className="Directive-name">
        {item}
      </li>
    ))
  }

  return (
    <div className={`Directive ${className}`}>
      <span className="Directive-icon">
        <i class={icon}></i>
      </span>
      <span className="Directive-title">{title}</span>
      <ul className="Directive-names">{renderNames()}</ul>
    </div>
  )
}

Directive.propTypes = {
  names: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  classNeme: PropTypes.string,
}

Directive.defaultProps = {
  className: "",
}

export default Directive
